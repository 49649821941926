import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { MDBDataTableV5 } from 'mdbreact'
import { allProfiles } from '../../../actions/adminActions/farmclusterActions/farmProfiles'
import moment from 'moment'
import { MessageBox, LoadingBox } from '../../../components/'
import { formatNumber } from '../../../components/functions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

export default function FarmProfilePage() {
    const [datatable, setDatatable] = useState({})
    const [info, setInfo] = useState({})
    const [open, setOpen] = useState(false)

    const allFarmProfiles = useSelector((state) => state.allFarmProfiles)
    const { loading, profiles, error } = allFarmProfiles

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(allProfiles())
    }, [])

    useEffect(() => {
        if (profiles) {
            setDatatable({
                columns: [

                    {
                        label: 'User ID',
                        field: 'phone',
                        width: 100,
                    },
                    {
                        label: 'Farm Name',
                        field: 'company',
                        width: 100,
                    },
                    {
                        label: 'Farming Type',
                        field: 'category',
                    },
                    {
                        label: 'Farm Size',
                        field: 'size',
                        width: 50,
                    },
                    {
                        label: 'Staff Capacity',
                        field: 'staffCapacity',
                        width: 50,
                    },
                    {
                        label: 'Date Created',
                        field: 'date',
                        width: 50,
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sort: 'disabled',
                    },
                ],
                rows: profiles.map((item) => ({
                    phone: item?.userId?.phone,
                    company: item?.farmName,
                    category: item?.farmingType,
                    size: `${item?.size?.value && formatNumber(item?.size?.value)} ${item?.size?.unit}`,
                    staffCapacity: item?.staffCapacity,
                    date: moment(item.createdAt).format('Do MMM YYYY, h:mm a'),
                    action: (
                        <>
                            <button
                                className="btn btn-sm btn-success text-edit"
                                style={{ marginRight: '15px' }}
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="View"
                                onClick={() => handleClickOpen(item)}
                            >
                                View
                            </button>
                        </>
                    ),
                })),
            })
        }
    }, [profiles])

    const handleClickOpen = (item) => {
        setInfo(item)
        setOpen(true)
    }

    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Farm Profiles</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmcluster</a>
                                            </li>
                                            <li className="breadcrumb-item active">Profiles</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title --> */}

                        <div className="card">
                            <div className="card-body">
                                {error ? <MessageBox variant='danger'>{error}</MessageBox> : null}

                                {
                                    loading ? <LoadingBox /> :
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive-lg">
                                                    <MDBDataTableV5
                                                        hover
                                                        entriesOptions={[5, 20, 25, 50]}
                                                        entries={10}
                                                        pagesAmount={4}
                                                        data={datatable}
                                                        searchTop
                                                        searchBottom={false}
                                                        barReverse
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="form-dialog-title"
                    maxWidth='md'
                    fullWidth
                >
                    <>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Farm Profile Detail
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setOpen(false)}
                            ></button>
                        </div>
                        <DialogContent className="">
                            <div className="mt-3">
                                <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%' }}>
                                    <div className="col-md-4">
                                        <img
                                            src={'/assets/images/farmclusters_logo.png'}
                                            alt="logo-sm-light"
                                            style={{
                                                width: '60%',

                                            }}
                                        />

                                        <hr
                                            style={{
                                                width: '100%',
                                                height: '0.1rem',
                                                color: 'lightgrey'
                                            }} />

                                        <div className="mt-4">
                                            <h5 className="font-size-12" style={{ fontWeight: 'bold', }}>
                                                Name
                                            </h5>

                                            <h5 className="card-title font-size-15" style={{ textTransform: 'capitalize' }}>
                                                {`${info?.userId?.firstName} ${info?.userId?.lastName}` || "-"}
                                            </h5>
                                        </div>

                                        <div className="mt-4">
                                            <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                                Phone
                                            </h5>

                                            <h5 className="card-title font-size-15">
                                                {info?.userId?.phone || "-"}
                                            </h5>
                                        </div>

                                        <div className="mt-4">
                                            <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                                Email
                                            </h5>

                                            <h5 className="card-title font-size-15">
                                                {info?.userId?.email || '-'}
                                            </h5>
                                        </div>

                                        <div className="mt-4">
                                            <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                                Address
                                            </h5>

                                            <h5 className="card-title font-size-15">
                                                {info?.userId?.address || '-'}
                                            </h5>
                                        </div>


                                        <div className="mt-4">
                                            <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                                Cluster(s)
                                            </h5>

                                            <h5 className="card-title font-size-15">
                                                {info?.cluster?.map((itm) => <div className={`badge badge-soft-success font-size-12`}>
                                                    {itm}
                                                </div>) || '-'}

                                            </h5>
                                        </div>

                                    </div>
                                    <div className="col-md-1" style={{ width: "10px", display: 'flex', justifyContent: 'center', alignItems: 'center', height: '26rem' }}>
                                        <hr
                                            style={{
                                                width: '0.1rem',
                                                height: '100%',
                                                color: 'lightgrey',
                                                alignSelf: 'center'
                                            }} />
                                    </div>
                                    <div className="col-md-7">

                                        <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div className="mt-2">
                                                <p style={{ marginBottom: '2px', fontSize: '16px' }}>Company Name:</p>
                                                <h2 style={{ fontWeight: '800', fontSize: '35px' }}>{info?.farmName}</h2>

                                                <p style={{ marginBottom: '8px', fontWeight: 'bold' }}>at {info?.location?.address}</p>
                                            </div>
                                        </div>

                                        <hr
                                            style={{
                                                width: '100%',
                                                height: '0.1rem',
                                                color: 'lightgrey'
                                            }} />

                                        <p style={{ marginBottom: '2px', fontSize: '16px', fontWeight: 'bold' }}>FARM DETAIL</p>

                                        <div className="mt-4">
                                            <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                                Staff Capacity
                                            </h5>

                                            <h5 className="card-title font-size-15">
                                                {info?.staffCapacity || '-'}
                                            </h5>
                                        </div>
                                        <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                            <div className="mt-3" style={{ flex: 1 }}>
                                                <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                                    Farming Type
                                                </h5>

                                                <h5 className="card-title font-size-15">
                                                    {info?.farmingType || '-'}
                                                </h5>
                                            </div>

                                            <div className="mt-3" style={{ flex: 1 }}>
                                                <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                                    Livestock Type
                                                </h5>

                                                <h5 className="card-title font-size-15">
                                                    {info?.livestockDetails?.livestockType || '-'}
                                                </h5>
                                            </div>

                                        </div>

                                        <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                            <div className="mt-4" style={{ flex: 1 }}>
                                                <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                                    Farm Capacity
                                                </h5>

                                                <h5 className="card-title font-size-15">
                                                    {`${info?.size?.value && formatNumber(info?.size?.value)} ${info?.size?.unit}` || '-'}
                                                </h5>
                                            </div>

                                            <div className="mt-4" style={{ flex: 1 }}>
                                                <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                                    Stock Capacity
                                                </h5>

                                                <h5 className="card-title font-size-15">
                                                    {`${info?.livestockDetails?.herdSize && formatNumber(info?.livestockDetails?.herdSize)} ${info?.livestockDetails?.unit}` || '-'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DialogActions>
                                <button
                                    className="btn btn-danger btn-MD"
                                    type="close"
                                    onClick={() => setOpen(false)}
                                >
                                    Close
                                </button>
                            </DialogActions>
                        </DialogContent>
                    </>
                </Dialog>
            </div>
            <Footer />
        </div >
    )
}
