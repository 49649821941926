import axios from 'axios'
import {
    SEND_PRODUCE_WAYBILL_FAIL,
    SEND_PRODUCE_WAYBILL_REQUEST,
    SEND_PRODUCE_WAYBILL_SUCCESS,
} from '../../../constants/adminConstants/farmcluster/clusterContants'

export const sendWaybill = (itemId) => async (dispatch, getState) => {
    dispatch({
        type: SEND_PRODUCE_WAYBILL_REQUEST,
        payload: { itemId },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/send-waybill',
            { itemId },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: SEND_PRODUCE_WAYBILL_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: SEND_PRODUCE_WAYBILL_FAIL, payload: message })
    }
}
