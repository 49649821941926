import {
  ACTIVITY_ADD_ITEM,
  ACTIVITY_EMPTY,
  ACTIVITY_REMOVE_ITEM,
  ADD_BENEFIT_REQUEST,
  BULK_ACTIVITY_ADD_ITEM,
  CLEAR_ACTIVITIES
} from '../../../constants/adminConstants/activityContants'

export const addToActivity = (
  _id,
  activity,
  tagColor,
  farmActivity,
  expectedTask,
  subTasks,
  timeLineFrom,
  timeLineTo,
  point,

) => async (dispatch, getState) => {
  dispatch({
    type: ACTIVITY_ADD_ITEM,
    payload: {
      _id,
      activity,
      tagColor,
      farmActivity,
      expectedTask,
      subTasks,
      timeLineFrom,
      timeLineTo,
      point,
    },
  })
  localStorage.setItem(
    'activityItems',
    JSON.stringify(getState().compliance.activityItems),
  )
}

export const bulkAddToActivity = (activities) => async (dispatch, getState) => {
  dispatch({
    type: BULK_ACTIVITY_ADD_ITEM,
    payload: activities,
  })
  localStorage.setItem(
    'activityItems',
    JSON.stringify(getState().compliance.activityItems),
  )
}

export const removeFromActivity = (_id) => (dispatch, getState) => {
  dispatch({ type: ACTIVITY_REMOVE_ITEM, payload: _id })
  localStorage.setItem(
    'activityItems',
    JSON.stringify(getState().compliance.activityItems),
  )
}

export const clearActivity = () => (dispatch) => {
  dispatch({ type: ACTIVITY_EMPTY })
  localStorage.removeItem('activityItems')
  localStorage.removeItem('benefitsItems')
}

export const clear = () => (dispatch) => {
  dispatch({ type: CLEAR_ACTIVITIES })
  localStorage.removeItem('activityItems')
}

export const addBenefit = (
  benefitName,
  benefit,
  benefitFrom,
  benefitTo,
  minPointToQualify,
) => async (dispatch, getState) => {
  dispatch({
    type: ADD_BENEFIT_REQUEST,
    payload: {
      benefitName,
      benefit,
      benefitFrom,
      benefitTo,
      minPointToQualify,
    },
  })
  localStorage.setItem(
    'benefitsItems',
    JSON.stringify(getState().compliance.benefitsItems),
  )
}
