import { ALL_CHATS_LIST_FAIL, ALL_CHATS_LIST_REQUEST, ALL_CHATS_LIST_SUCCESS } from "../../constants/authConstants/ChatConstants"

export const userChatHistoryReducer = (
    state = { loading: true, messages: [] },
    action,
) => {
    switch (action.type) {
        case ALL_CHATS_LIST_REQUEST:
            return { loading: true }
        case ALL_CHATS_LIST_SUCCESS:
            return {
                loading: false,
                history: action.payload,
            }
        case ALL_CHATS_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
