import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../partials'
import { useDispatch, useSelector } from 'react-redux';
import { chatHistory } from "../../actions/authActions/chatHistory"
import ChatMessage from '../../components/ChatMessage';
import LoadingBox from '../../components/LoadingBox';
import moment from 'moment'

export default function ChatPage() {
    const [show, setShow] = useState(false)
    const [messages, setMessage] = useState([])
    const [userId, setUserId] = useState('')

    const messageHistory = useSelector((state) => state.messageHistory)
    const { loading, history, error } = messageHistory

    const userSignin = useSelector((state) => state.userSignin)
    const { userInfo } = userSignin

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(chatHistory())
    }, [])

    const showChatComponment = (item) => {
        setShow(true)
        setMessage(item.messages)
        let user = item?.participants[0]?._id === userInfo._id ? item?.participants[1]?._id : item?.participants[0]?._id
        setUserId(user)
        console.log("userId", user)
    }

    return (
        <div id="layout-wrapper">
            <Header />
            <RightSidebar />
            <LeftSidebar />
            <div className="main-content">
                {
                    loading ? <LoadingBox /> :

                        <div className="page-content">
                            <div className="container-fluid">

                                {/* <!-- start page title --> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <h4 className="mb-sm-0">Chat</h4>

                                            <div className="page-title-right">
                                                <ol className="breadcrumb m-0">
                                                    <li className="breadcrumb-item"><a href="javascript: void(0);">Farmsured</a></li>
                                                    <li className="breadcrumb-item active">Chat</li>
                                                </ol>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end page title --> */}

                                <div className="d-lg-flex mb-4" style={{ height: "650px" }}>
                                    <div className="chat-leftsidebar">
                                        <div className="p-3 border-bottom">
                                            <div className="d-flex">
                                                <div className="align-self-center me-3">
                                                    <img src="assets/images/users/avatar-2.jpg" className="avatar-xs rounded-circle" alt="avatar-2" />
                                                </div>
                                                <div className="flex-1">
                                                    <h5 className="font-size-15 mb-1">{userInfo?.phone}</h5>
                                                    <p className="text-muted mb-0"><i className="mdi mdi-circle text-success align-middle me-1"></i> Active</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body border-bottom py-2">
                                            <div className="search-box chat-search-box">
                                                <div className="position-relative">
                                                    <input type="text" className="form-control" placeholder="Search..." />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-content py-4">
                                            <div className="tab-pane show active" id="chat">
                                                <div>
                                                    <h5 className="font-size-14 px-3 mb-3">Recent</h5>
                                                    <ul className="list-unstyled chat-list" data-simplebar="init" style={{ maxHeight: "345px" }}>
                                                        <div className="simplebar-wrapper" style={{ margin: "0px" }}>
                                                            <div className="simplebar-height-auto-observer-wrapper">
                                                                <div className="simplebar-height-auto-observer">
                                                                </div>
                                                            </div>
                                                            <div className="simplebar-mask">
                                                                <div className="simplebar-offset" style={{ right: "-17px", bottom: "0px" }}>
                                                                    <div className="simplebar-content-wrapper" style={{ height: "auto", overflow: "hidden scroll" }}>
                                                                        <div className="simplebar-content" style={{ padding: "0px" }}>
                                                                            {
                                                                                history?.length > 0 ?
                                                                                    <>
                                                                                        {
                                                                                            history.map((item, index) => (
                                                                                                <li className="active" key={index}>
                                                                                                    <a onClick={() => showChatComponment(item)}>
                                                                                                        <div className="d-flex">
                                                                                                            <div className="user-img online align-self-center me-3">
                                                                                                                <img src="assets/images/users/avatar-2.jpg" className="rounded-circle avatar-xs" alt="avatar-2" />
                                                                                                            </div>

                                                                                                            <div className="flex-1 overflow-hidden">
                                                                                                                <h5 className="text-truncate font-size-14 mb-1">{item?.participants[0]?._id === userInfo._id ? item?.participants[1]?.phone : item?.participants[0]?.phone}</h5>
                                                                                                                <p className="text-truncate mb-0">{item?.lastMessage}</p>
                                                                                                            </div>
                                                                                                            <div className="font-size-11">{moment(item.lastUpdated).startOf('hour').fromNow()}</div>
                                                                                                        </div>
                                                                                                    </a>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="simplebar-placeholder" style={{ width: "auto", height: "500px" }}></div>
                                                        </div>
                                                        <div className="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }}>
                                                            <div className="simplebar-scrollbar" style={{ transform: "translate3d(0px, 0px, 0px)", display: "none" }}>
                                                            </div>
                                                        </div>
                                                        <div className="simplebar - track simplebar- vertical" style={{ visibility: "visible" }}>
                                                            <div className="simplebar-scrollbar" style={{ height: "238px", transform: "translate3d(0px, 0px, 0px)", display: "block" }}>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {show ? <ChatMessage history={messages} receiverId={userId} /> : null}
                                </div>

                            </div>
                        </div>
                }
            </div>
            <Footer />
        </div>
    )
}
