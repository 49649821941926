import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'poppin',
        padding: 20,
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 40,
        paddingRight: 40,
        lineHeight: 1.5,
    },
    section: {
        marginBottom: 10,
        padding: 6,
    },
    table: {
        display: 'table',
        width: '100%',
        borderCollapse: 'collapse',
    },
    row: {
        flexDirection: 'row',
    },
    cell: {
        border: '1px solid black',
        padding: 8,
        fontSize: 12,
        flex: 1,
    },
    headerCell: {
        border: '1px solid black',
        padding: 8,
        fontSize: 12,
        fontWeight: 'bold',
        backgroundColor: '#f0f0f0',
        flex: 1,
    },
    logo: {
        width: 150,
        height: 'auto',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    text: {
        fontSize: 12,
    },
});

const WaybillPdf = () => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* Header Section */}
            <View style={styles.section}>
                <View style={[styles.row, { alignItems: 'center' }]}>
                    <Image style={styles.logo} src="https://www.panoyagribusiness.com/images/1920/11684105/panoyag_logo.png" />
                    <View style={{ marginLeft: 20 }}>
                        <Text style={styles.title}>FarmClusters Egg Delivery Waybill</Text>
                        <Text style={styles.text}>Waybill Number: PANOY-AG//202411//00011</Text>
                        <Text style={styles.text}>Date of Issue: 22/11/2024</Text>
                    </View>
                </View>
            </View>

            {/* Sender Information */}
            <View style={styles.section}>
                <Text style={styles.title}>SENDER INFORMATION</Text>
                <Text style={styles.text}>Cluster Name: PANOY-AG/IBADAN 02/CLUSTER 020</Text>
                <Text style={styles.text}>Cluster Address: LAGUN, IYANA OFFA</Text>
                <Text style={styles.text}>Contact Person: PANOY AGRIBUSINESS LTD.</Text>
            </View>

            {/* Receiver Information */}
            <View style={styles.section}>
                <Text style={styles.title}>RECEIVER INFORMATION</Text>
                <Text style={styles.text}>Recipient Name: ANIMAL CARE SERVICES KONSULT LTD</Text>
                <Text style={styles.text}>Aggregation Center: OLUYOLE EST. IBADAN</Text>
                <Text style={styles.text}>Contact Person: Olowade Seun</Text>
            </View>

            {/* Logistics Details */}
            <View style={styles.section}>
                <Text style={styles.title}>LOGISTICS AND DELIVERY DETAILS</Text>
                <Text style={styles.text}>Date of Delivery: 22/11/2024</Text>
                <Text style={styles.text}>Expected Delivery Time: 8:00am</Text>
                <Text style={styles.text}>Vehicle Plate Number: LUY 806 YT</Text>
                <Text style={styles.text}>Logistics Officer: Oluseyi Fehintola / 09040761248</Text>
            </View>

            {/* Product Details Table */}
            <View style={styles.section}>
                <Text style={styles.title}>PRODUCT DETAILS</Text>
                <View style={styles.table}>
                    <View style={styles.row}>
                        <Text style={styles.headerCell}>Description</Text>
                        <Text style={styles.headerCell}>Quantity (in Crates)</Text>
                        <Text style={styles.headerCell}>Price per Crate (NGN)</Text>
                        <Text style={styles.headerCell}>Total Value (NGN)</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.cell}>Fresh Eggs</Text>
                        <Text style={styles.cell}>1004</Text>
                        <Text style={styles.cell}>-</Text>
                        <Text style={styles.cell}>-</Text>
                    </View>
                </View>
            </View>

            {/* Signature Section */}
            <View style={styles.section}>
                <Text style={styles.title}>SIGNATURES</Text>
                <View style={styles.row}>
                    <View style={{ flex: 1, alignItems: 'center' }}>
                        <Text style={styles.text}>Sender's Signature:</Text>
                        <Text>______________</Text>
                        <Text>Date: 22/11/2024</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'center' }}>
                        <Text style={styles.text}>Driver's Signature:</Text>
                        <Text>______________</Text>
                        <Text>Date: 22/11/2024</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'center' }}>
                        <Text style={styles.text}>Receiver's Signature:</Text>
                        <Text>______________</Text>
                        <Text>Date: 22/11/2024</Text>
                    </View>
                </View>
            </View>

            {/* Account Details */}
            <View style={styles.section}>
                <Text style={styles.title}>ACCOUNT DETAILS FOR PAYMENT</Text>
                <Text style={styles.text}>Account Name: Agribusiness Dynamics Tech Ltd</Text>
                <Text style={styles.text}>Bank: Providus Bank</Text>
                <Text style={styles.text}>Account Number: 7901198023</Text>
            </View>
        </Page>
    </Document>
);

export default WaybillPdf;
