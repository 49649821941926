import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { farmProduce } from '../../../actions/adminActions/farmclusterActions/farmProduce'
import LoadingBox from '../../../components/LoadingBox'
import MessageBox from '../../../components/MessageBox'
import { formatNumber } from '../../../components/functions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { listManufacuturers } from '../../../actions/adminActions/manufactuerActions/listManufacturers'
import { allServiceCategory } from '../../../actions/adminActions/serviceActions/allServiceCategory'
import { AllService } from '../../../actions/adminActions/serviceActions/allServices'
import { updateProduceListingStatus } from '../../../actions/adminActions/farmclusterActions/updateProduceListingStatus'
import { SEND_PRODUCE_WAYBILL_RESET, UPDATE_PRODUCE_LIST_RESET } from '../../../constants/adminConstants/farmcluster/clusterContants'
import { sendWaybill } from '../../../actions/adminActions/farmclusterActions/sendWaybill'
import { PDFDownloadLink } from '@react-pdf/renderer'
import WaybillPdf from '../../../components/WaybillPdf'

export default function ProduceListingPage() {
  const [datatable, setDatatable] = useState({})
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [itemId, setItemId] = useState(false)
  const [offtaker, setOfftaker] = useState('')
  const [offtakerDetail, setOfftakerDetail] = useState({})
  const [seller, setSeller] = useState('')
  const [sellerDetail, setSellerDetail] = useState({})
  const [category, setCategory] = useState('')
  const [serviceProvider, setServiceProvider] = useState('')
  const [selectedSP, setSelectedSP] = useState('')
  const [serviceProviderDetail, setServiceProviderDetail] = useState({})
  const [dialogInfo, setDialogInfo] = useState({})

  const produceList = useSelector((state) => state.produceList)
  const {
    loading,
    error,
    list,
  } = produceList

  const manufacturersList = useSelector((state) => state.manufacturersList)
  const { loading: loadingMaufacturer, manufacturers } = manufacturersList

  const serviceCategory = useSelector((state) => state.serviceCategory)
  const { loading: loadingCategory, categorylist } = serviceCategory

  // All Service State
  const serviceList = useSelector((state) => state.serviceList)
  const { allservices, } = serviceList

  const updateProduceListing = useSelector((state) => state.updateProduceListing)
  const { loading: loadingUpdate, updated, error: updateError } = updateProduceListing

  const sendProduceWaybill = useSelector((state) => state.sendProduceWaybill)
  const {
    loading: sendWaybillLoading,
    error: sendWaybillError,
    sent,
  } = sendProduceWaybill

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(farmProduce())
    dispatch(listManufacuturers())
    dispatch(allServiceCategory())
    dispatch(AllService())

    if (updated) {
      setOfftaker('')
      setSeller('')
      setSelectedSP('')
      setCategory('')
      setShow(false)
      setTimeout(() => {
        dispatch({ type: UPDATE_PRODUCE_LIST_RESET })
      }, 5000)
    }

    if (sent) {
      setTimeout(() => {
        dispatch({ type: SEND_PRODUCE_WAYBILL_RESET })
      }, 5000)
    }

  }, [updated, sent])

  useEffect(() => {
    if (updateError) {
      setShow(false)
      setTimeout(() => {
        dispatch({ type: UPDATE_PRODUCE_LIST_RESET })
      }, 5000)
    }

    if (sendWaybillError) {
      setTimeout(() => {
        dispatch({ type: SEND_PRODUCE_WAYBILL_RESET })
      }, 5000)
    }
  }, [updateError, sendWaybillError])

  useEffect(() => {
    if (list) {
      setDatatable({
        columns: [
          {
            label: 'Farmer ID',
            field: 'userID',
            width: 100,
          },
          {
            label: 'Product',
            field: 'product',
            width: 100,
          },
          {
            label: 'Quantity',
            field: 'qty',
          },
          {
            label: 'Price',
            field: 'price',
          },
          {
            label: 'Pickup Location',
            field: 'location',
          },
          {
            label: 'Pickup Date',
            field: 'pickupDate',
          },
          {
            label: 'Date Created',
            field: 'date',
            width: 50,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: list.map((item) => ({
          userID: item.createdBy?.phone,
          product: item.product,
          qty: item?.quantity ? formatNumber(item?.quantity) : 0,
          price: `₦${item?.amount ? formatNumber(item?.amount) : 0}`,
          location: item.location,
          pickupDate: moment(item.pickupDate).format('Do MMM YYYY'),
          date: moment(item.createdAt).format('Do MMM YYYY, h:mm a'),
          action: (
            <>
              <button
                className="btn btn-sm btn-success text-edit"
                style={{ marginRight: '15px' }}
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Manage"
                onClick={() => handleClickOpen(item)}
              >
                Manage
              </button>
            </>
          ),
        })),
      })
    }
  }, [list])

  const handleClickOpen = (item) => {
    setDialogInfo(item)
    setItemId(item._id)
    setOpen(true)
  }

  useEffect(() => {
    if (category) {
      let filteredService = allservices.filter((itm) => itm.category.find((item) => item._id === category))
      setServiceProvider(filteredService)
    }
  }, [category])

  const handleUpdateButton = (e) => {
    e.preventDefault()
    dispatch(updateProduceListingStatus(itemId, offtaker, seller, selectedSP))
  }

  const handleSendButton = (e) => {
    e.preventDefault()
    dispatch(sendWaybill(itemId))
  }

  const handleChangeOfftaker = (e) => {
    e.preventDefault()
    setOfftaker(e.target.value)

    let result = manufacturers.find((itm) => itm?._id === e.target.value)
    setOfftakerDetail(result)
  }

  const handleChangeSeller = (e) => {
    setSeller(e.target.value)

    let result = manufacturers.find((itm) => itm?._id === e.target.value)
    setSellerDetail(result)
  }

  const handleChangeServiceProvider = (e) => {
    setSelectedSP(e.target.value)

    let result = allservices.find((itm) => itm?._id === e.target.value)
    setServiceProviderDetail(result)
  }

  const handleClose = () => {
    setOpen(false)
    setShow(false)
    setOfftaker('')
    setSeller('')
    setSelectedSP('')
    setCategory('')
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <RightSidebar />
      <LeftSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Produce Listing</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmclusters</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Farm Produce
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">

                <div className="row">
                  {
                    loading
                      ? <LoadingBox />
                      :
                      <div className="col-md-12">
                        <div className="table-responsive-lg">
                          {error ? <MessageBox variant='danger'>{error}</MessageBox> : null}
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => (setOpen(false), setShow(false))}
        aria-labelledby="form-dialog-title"
        maxWidth='lg'
        fullWidth
      >
        <>
          {loadingUpdate && <LoadingBox />}
          {sendWaybillLoading && <LoadingBox />}

          <div className="modal-header">
            <h5 className="modal-title">
              Manage Produce Listing
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => (setOpen(false), setShow(false))}
            ></button>
          </div>
          <DialogContent className="">

            {updated && (
              <MessageBox variant="success">
                {updated.message}
              </MessageBox>
            )}

            {sent && (
              <MessageBox variant="success">
                {sent.message}
              </MessageBox>
            )}

            {updateError && (
              <MessageBox variant="danger">
                {updateError}
              </MessageBox>
            )}

            {
              sendWaybillError && (
                <MessageBox variant="danger">
                  {sendWaybillError}
                </MessageBox>
              )
            }
            <div className="mt-3">
              <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                <div className="col-md-4">
                  <img
                    src={'/assets/images/farmclusters_logo.png'}
                    alt="logo-sm-light"
                    style={{
                      width: '60%',

                    }}
                  />

                  <hr
                    style={{
                      width: '100%',
                      height: '0.1rem',
                      color: 'lightgrey'
                    }} />

                  <div className="mt-4">
                    <h5 className="font-size-12" style={{ fontWeight: 'bold', }}>
                      Customer Name
                    </h5>

                    <h5 className="card-title font-size-15" style={{ textTransform: 'capitalize' }}>
                      {`${dialogInfo?.createdBy?.firstName} ${dialogInfo?.createdBy?.lastName}` || "-"}
                    </h5>
                  </div>

                  <div className="mt-4">
                    <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                      Customer Phone
                    </h5>

                    <h5 className="card-title font-size-15">
                      {dialogInfo?.createdBy?.phone}
                    </h5>
                  </div>

                  <div className="mt-4">
                    <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                      Address
                    </h5>

                    <h5 className="card-title font-size-15">
                      {dialogInfo?.createdBy?.address || '-'}
                    </h5>
                  </div>

                  <div className="mt-4">
                    <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                      Cluster
                    </h5>

                    <h5 className="card-title font-size-15">
                      {dialogInfo?.cluster?.map((itm) => <div className={`badge badge-soft-success font-size-12`}>
                        {itm}
                      </div>) || '-'}

                    </h5>
                  </div>

                  <hr
                    style={{
                      width: '100%',
                      height: '0.1rem',
                      color: 'lightgrey'
                    }} />

                  <div className="mt-4">
                    <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                      Product
                    </h5>

                    <h5 className="card-title font-size-15">
                      {dialogInfo?.product}
                    </h5>
                  </div>

                  <div className="mt-4">
                    <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                      Quantity
                    </h5>

                    <h5 className="card-title font-size-15">
                      {`${dialogInfo?.quantity ? formatNumber(dialogInfo?.quantity) : 0} ${dialogInfo?.unit || "unit"}`}
                    </h5>
                  </div>

                  <div className="mt-4">
                    <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                      Price
                    </h5>

                    <h5 className="card-title font-size-15">
                      {`₦${dialogInfo?.amount ? formatNumber(dialogInfo?.amount) : 0}`}
                    </h5>
                  </div>
                </div>
                <div className="col-md-1" style={{ width: "10px", display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35rem' }}>
                  <hr
                    style={{
                      width: '0.1rem',
                      height: '100%',
                      color: 'lightgrey',
                      alignSelf: 'center'
                    }} />
                </div>
                <div className="col-md-7">
                  <div className="">
                    <h3 style={{ marginBottom: '2px', fontSize: '16px', fontWeight: 'bold' }}>OFFTAKING DETAILS</h3>

                    {
                      show
                        ?
                        <div>
                          <h4 style={{ marginBottom: '12px', fontSize: '15px', fontWeight: '800', marginTop: "30px" }}>Receiver Information</h4>

                          <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold', }}>
                                Company Name
                              </h5>

                              <h5 className="card-title font-size-15">
                                {offtakerDetail?.name || "-"}
                              </h5>
                            </div>

                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Contact Person
                              </h5>

                              <h5 className="card-title font-size-15">
                                {offtakerDetail?.contactPerson || "-"}
                              </h5>
                            </div>
                          </div>

                          <div className="mt-4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>

                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Email
                              </h5>

                              <h5 className="card-title font-size-15">
                                {offtakerDetail?.email || "-"}
                              </h5>
                            </div>

                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Phone
                              </h5>

                              <h5 className="card-title font-size-15">
                                {offtakerDetail?.phone || "-"}
                              </h5>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Address
                              </h5>

                              <h5 className="card-title font-size-15">
                                {offtakerDetail?.address || "-"}
                              </h5>
                            </div>
                          </div>


                          <hr
                            style={{
                              width: '100%',
                              height: '0.1rem',
                              color: 'lightgrey'
                            }} />

                          <h4 style={{ marginBottom: '1px', fontSize: '15px', fontWeight: '800', marginTop: "30px" }}>Sender Information</h4>

                          <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <div className="mt-3" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold', }}>
                                Company Name
                              </h5>

                              <h5 className="card-title font-size-15">
                                {sellerDetail?.name || "-"}
                              </h5>
                            </div>

                            <div className="mt-3" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Contact Person
                              </h5>

                              <h5 className="card-title font-size-15">
                                {sellerDetail?.contactPerson || "-"}
                              </h5>
                            </div>


                          </div>

                          <div className="mt-4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Email
                              </h5>

                              <h5 className="card-title font-size-15">
                                {sellerDetail?.email || "-"}
                              </h5>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Phone
                              </h5>

                              <h5 className="card-title font-size-15">
                                {sellerDetail?.phone || "-"}
                              </h5>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Address
                              </h5>

                              <h5 className="card-title font-size-15">
                                {sellerDetail?.address || "-"}
                              </h5>
                            </div>
                          </div>

                          <hr
                            style={{
                              width: '100%',
                              height: '0.1rem',
                              color: 'lightgrey'
                            }} />

                          <h4 style={{ marginBottom: '1px', fontSize: '15px', fontWeight: '800', marginTop: "30px" }}>Service Provider Details</h4>

                          <div className="mt-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold', }}>
                                Name
                              </h5>

                              <h5 className="card-title font-size-15">
                                {serviceProviderDetail?.name}
                              </h5>
                            </div>

                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Email
                              </h5>

                              <h5 className="card-title font-size-15">
                                {serviceProviderDetail?.email}
                              </h5>
                            </div>
                          </div>

                          <div className="mt-4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>

                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Phone
                              </h5>

                              <h5 className="card-title font-size-15">
                                {serviceProviderDetail?.phone}
                              </h5>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                Address
                              </h5>

                              <h5 className="card-title font-size-15">
                                {serviceProviderDetail?.address}
                              </h5>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                              <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                State
                              </h5>

                              <h5 className="card-title font-size-15">
                                {serviceProviderDetail?.state}
                              </h5>
                            </div>
                          </div>



                          <DialogActions>
                            <button
                              className="btn btn-danger btn-md mt-3"
                              type="close"
                              onClick={() => (setShow(false))}
                            >
                              Back
                            </button>

                            <button
                              className="btn btn-success btn-md mt-3"
                              type="submit"
                              onClick={handleUpdateButton}
                            >
                              Update
                            </button>
                          </DialogActions>
                        </div>
                        :
                        <div className="">
                          <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="">
                              <p style={{ marginBottom: '2px' }}>Order ID</p>
                              <h6 style={{ padding: 0 }}>#{dialogInfo?.orderID || ' - '}</h6>
                            </div>
                            <div className="">
                              <div className={`badge badge-soft-success font-size-12`}>
                                {dialogInfo.status}
                              </div>
                            </div>
                          </div>
                          <hr
                            style={{
                              width: '100%',
                              height: '0.1rem',
                              color: 'lightgrey'
                            }} />
                          <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="mt-2">
                              <p style={{ marginBottom: '2px', fontSize: '16px' }}>The order is scheduled:</p>
                              <h2 style={{ fontWeight: '800', fontSize: '35px' }}>Pick-up</h2>
                              <p style={{ marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }}>on {moment(dialogInfo.pickupDate).format(
                                'Do MMMM YYYY,  h:mm a',
                              )}</p>

                              <p style={{ marginBottom: '8px', fontWeight: 'bold' }}>at {dialogInfo.location}</p>
                            </div>

                            <div className="card" style={{ width: '40%' }}>
                              <div className="card-body">
                                <div className="" >
                                  <p className='mb-2'>Send Waybill to offtaker</p>
                                  <div className="d-grid">
                                    <button className='btn btn-sm btn-success' onClick={dialogInfo?.offtaker ? handleSendButton : null} disabled={dialogInfo?.offtaker ? false : true}><i className='fal fa-paper-plane me-1' ></i> Send waybill</button>
                                  </div>
                                </div>

                                {/* <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                          <p className='m-0 mt-2'><i className='fal fa-paper-plane me-1'></i>Download</p>
                          <div className="d-grid">
                            <PDFDownloadLink
                              document={
                                <WaybillPdf
                                  detail={dialogInfo}
                                />
                              }
                              fileName={`Waybill-${dialogInfo.orderID}.pdf`}
                              className='btn btn-sm btn-success'
                            >
                              {({ loading }) =>
                                loading ? 'Loading...' : 'Download Waybill'
                              }
                            </PDFDownloadLink>
                          </div>
                        </div> */}
                              </div>
                            </div>
                          </div>
                          <hr
                            style={{
                              width: '100%',
                              height: '0.1rem',
                              color: 'lightgrey'
                            }} />
                          <div className="mt-4">
                            <div className="row">
                              <div className="col-md-8">
                                {
                                  dialogInfo?.offtaker ? <div className="mt-4">
                                    <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                      Produce Offtaker
                                    </h5>

                                    <h5 className="card-title font-size-15">
                                      {dialogInfo?.offtaker?.name}
                                    </h5>
                                  </div> : <>
                                    <label
                                      className="form-label"
                                      forHtml="offtaker"
                                    >
                                      Select the Offtaker
                                    </label>
                                    <select
                                      onChange={(e) => handleChangeOfftaker(e)}
                                      className="select2 form-select"
                                      required
                                      value={offtaker}
                                    >
                                      <option>Select</option>
                                      {loadingMaufacturer ? (
                                        'Loading...'
                                      ) : manufacturers.length > 0 ? (
                                        <>
                                          {manufacturers.map((item) => (
                                            <option value={item._id}>
                                              {item.name}
                                            </option>
                                          ))}
                                        </>
                                      ) : (
                                        'There is no organisation'
                                      )}
                                    </select></>
                                }

                              </div>
                            </div>
                          </div>

                          <div className="mt-4">
                            <div className="row">
                              <div className="col-md-8">
                                {
                                  dialogInfo?.offtaker ? <div className="mt-2">
                                    <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                      Produce Seller
                                    </h5>

                                    <h5 className="card-title font-size-15">
                                      {dialogInfo?.seller?.name}
                                    </h5>
                                  </div> :
                                    <>
                                      <label
                                        className="form-label"
                                        forHtml="seller"
                                      >
                                        Select the Seller
                                      </label>
                                      <select
                                        onChange={(e) => handleChangeSeller(e)}
                                        className="select2 form-select"
                                        required
                                        value={seller}
                                      >
                                        <option>Select</option>
                                        {loadingMaufacturer ? (
                                          'Loading...'
                                        ) : manufacturers.length > 0 ? (
                                          <>
                                            {manufacturers.map((item) => (
                                              <option value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </>
                                        ) : (
                                          'There is no organisation'
                                        )}
                                        <option value={'farmer'}>Farmer</option>
                                      </select>
                                    </>
                                }

                              </div>
                            </div>
                          </div>

                          <div className="mt-4">
                            {
                              dialogInfo?.offtaker ? <div className="mt-2">
                                <h5 className="font-size-12" style={{ fontWeight: 'bold' }}>
                                  Service Provider
                                </h5>

                                <h5 className="card-title font-size-15">
                                  {dialogInfo?.serviceProvider?.name || "-"}
                                </h5>
                              </div> :
                                <>
                                  <div className="row">
                                    <div className="col-md-5">
                                      <label
                                        className="form-label"
                                        forHtml="category"
                                      >
                                        Select Service Provider Category
                                      </label>

                                      <select
                                        onChange={(e) => setCategory(e.target.value)}
                                        className="select2 form-select"
                                        required
                                        value={category}
                                      >
                                        <option>Select</option>
                                        {loadingCategory ? (
                                          'Loading...'
                                        ) : categorylist.length > 0 ? (
                                          <>
                                            {categorylist.map((item) => (
                                              <option value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </>
                                        ) : (
                                          'Empty category'
                                        )}
                                      </select>
                                    </div>
                                    <div className="col-md-6">
                                      <label
                                        className="form-label"
                                        forHtml="serviceProvider"
                                      >
                                        Assign a Service Provider (Distributor, Logistics, etc.)
                                      </label>
                                      <select
                                        onChange={(e) => handleChangeServiceProvider(e)}
                                        className="select2 form-select"
                                        required
                                        value={selectedSP}
                                      >
                                        <option>Select</option>
                                        {serviceProvider.length > 0 ? (
                                          <>
                                            {serviceProvider.map((item) => (
                                              <option value={item._id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </>
                                        ) : (
                                          'Empty service providers'
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                </>
                            }

                          </div>
                          <DialogActions>
                            <button
                              className={`btn btn-${dialogInfo?.offtaker ? "danger": "success"} btn-md mt-3`}
                              type="submit"
                              onClick={dialogInfo?.offtaker ? handleClose : () => setShow(true)}
                            >
                              {dialogInfo?.offtaker ? "Close" : "Next"}
                            </button>
                          </DialogActions>
                        </div>
                    }
                  </div>
                </div>
              </div>



            </div>
          </DialogContent>
        </>
      </Dialog>
      <Footer />
    </div>
  )
}
