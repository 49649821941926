import {
    ADD_CLUSTER_CATEGORY_FAIL,
    ADD_CLUSTER_CATEGORY_REQUEST,
    ADD_CLUSTER_CATEGORY_RESET,
    ADD_CLUSTER_CATEGORY_SUCCESS,
    ADD_CLUSTER_FAIL,
    ADD_CLUSTER_REQUEST,
    ADD_CLUSTER_RESET,
    ADD_CLUSTER_SUCCESS,
    CLUSTER_CATEGORY_DELETE_FAIL,
    CLUSTER_CATEGORY_DELETE_REQUEST,
    CLUSTER_CATEGORY_DELETE_RESET,
    CLUSTER_CATEGORY_DELETE_SUCCESS,
    CLUSTER_CATEGORY_LIST_FAIL,
    CLUSTER_CATEGORY_LIST_REQUEST,
    CLUSTER_CATEGORY_LIST_SUCCESS,
    CLUSTER_DELETE_FAIL,
    CLUSTER_DELETE_REQUEST,
    CLUSTER_DELETE_RESET,
    CLUSTER_DELETE_SUCCESS,
    CLUSTER_LIST_FAIL,
    CLUSTER_LIST_REQUEST,
    CLUSTER_LIST_SUCCESS,
    FARM_PRODUCE_LIST_FAIL,
    FARM_PRODUCE_LIST_REQUEST,
    FARM_PRODUCE_LIST_SUCCESS,
    FARM_PROFILES_FAIL,
    FARM_PROFILES_REQUEST,
    FARM_PROFILES_SUCCESS,
    SEND_PRODUCE_WAYBILL_FAIL,
    SEND_PRODUCE_WAYBILL_REQUEST,
    SEND_PRODUCE_WAYBILL_RESET,
    SEND_PRODUCE_WAYBILL_SUCCESS,
    UPDATE_CLUSTER_CATEGORY_FAIL,
    UPDATE_CLUSTER_CATEGORY_REQUEST,
    UPDATE_CLUSTER_CATEGORY_RESET,
    UPDATE_CLUSTER_CATEGORY_SUCCESS,
    UPDATE_CLUSTER_FAIL,
    UPDATE_CLUSTER_REQUEST,
    UPDATE_CLUSTER_RESET,
    UPDATE_CLUSTER_SUCCESS,
    UPDATE_PRODUCE_LIST_FAIL,
    UPDATE_PRODUCE_LIST_REQUEST,
    UPDATE_PRODUCE_LIST_RESET,
    UPDATE_PRODUCE_LIST_SUCCESS
} from "../../../constants/adminConstants/farmcluster/clusterContants"

export const addClusterReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_CLUSTER_REQUEST:
            return { loading: true }
        case ADD_CLUSTER_SUCCESS:
            return { loading: false, cluster: action.payload }
        case ADD_CLUSTER_FAIL:
            return { loading: false, error: action.payload }
        case ADD_CLUSTER_RESET:
            return {}
        default:
            return state
    }
}

export const clusterListReducer = (state = { loading: true, clusters: [] }, action) => {
    switch (action.type) {
        case CLUSTER_LIST_REQUEST:
            return { loading: true }
        case CLUSTER_LIST_SUCCESS:
            return { loading: false, clusters: action.payload }
        case CLUSTER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const updateClusterReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CLUSTER_REQUEST:
            return { loading: true }
        case UPDATE_CLUSTER_SUCCESS:
            return { loading: false, success: true, updateSuccess: action.payload, }
        case UPDATE_CLUSTER_FAIL:
            return { loading: false, error: action.payload }
        case UPDATE_CLUSTER_RESET:
            return {}
        default:
            return state
    }
}

export const clusterDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CLUSTER_DELETE_REQUEST:
            return { loading: true }
        case CLUSTER_DELETE_SUCCESS:
            return { loading: false, success: true }
        case CLUSTER_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case CLUSTER_DELETE_RESET:
            return {}
        default:
            return state
    }
}

export const clusterCategoryListReducer = (
    state = { loading: true, categorylist: [] },
    action,
) => {
    switch (action.type) {
        case CLUSTER_CATEGORY_LIST_REQUEST:
            return { loading: true }
        case CLUSTER_CATEGORY_LIST_SUCCESS:
            return { loading: false, categorylist: action.payload }
        case CLUSTER_CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const addClusterCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_CLUSTER_CATEGORY_REQUEST:
            return { loading: true }
        case ADD_CLUSTER_CATEGORY_SUCCESS:
            return { loading: false, category: action.payload }
        case ADD_CLUSTER_CATEGORY_FAIL:
            return { loading: false, error: action.payload }
        case ADD_CLUSTER_CATEGORY_RESET:
            return {}
        default:
            return state
    }
}

export const clusterCategoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CLUSTER_CATEGORY_DELETE_REQUEST:
            return { loading: true }
        case CLUSTER_CATEGORY_DELETE_SUCCESS:
            return { loading: false, success: true }
        case CLUSTER_CATEGORY_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case CLUSTER_CATEGORY_DELETE_RESET:
            return {}
        default:
            return state
    }
}

export const updateClusterCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CLUSTER_CATEGORY_REQUEST:
            return { loading: true }
        case UPDATE_CLUSTER_CATEGORY_SUCCESS:
            return { loading: false, success: true }
        case UPDATE_CLUSTER_CATEGORY_FAIL:
            return { loading: false, error: action.payload }
        case UPDATE_CLUSTER_CATEGORY_RESET:
            return {}
        default:
            return state
    }
}

export const farmProduceListReducer = (state = { list: [] }, action) => {
    switch (action.type) {
        case FARM_PRODUCE_LIST_REQUEST:
            return { loading: true }
        case FARM_PRODUCE_LIST_SUCCESS:
            return { loading: false, list: action.payload }
        case FARM_PRODUCE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const updateProduceListingReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PRODUCE_LIST_REQUEST:
            return { loading: true }
        case UPDATE_PRODUCE_LIST_SUCCESS:
            return { loading: false, updated: action.payload }
        case UPDATE_PRODUCE_LIST_FAIL:
            return { loading: false, error: action.payload }
        case UPDATE_PRODUCE_LIST_RESET:
            return {}
        default:
            return state
    }
}

export const sendWaybillReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_PRODUCE_WAYBILL_REQUEST:
            return { loading: true }
        case SEND_PRODUCE_WAYBILL_SUCCESS:
            return { loading: false, sent: action.payload }
        case SEND_PRODUCE_WAYBILL_FAIL:
            return { loading: false, error: action.payload }
        case SEND_PRODUCE_WAYBILL_RESET:
            return {}
        default:
            return state
    }
}

export const farmProfilesReducer = (state = { profiles: [] }, action) => {
    switch (action.type) {
        case FARM_PROFILES_REQUEST:
            return { loading: true }
        case FARM_PROFILES_SUCCESS:
            return { loading: false, profiles: action.payload }
        case FARM_PROFILES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}