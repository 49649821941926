import axios from 'axios'
import {
    ALL_CHATS_LIST_FAIL,
    ALL_CHATS_LIST_REQUEST,
    ALL_CHATS_LIST_SUCCESS,
} from '../../constants/authConstants/ChatConstants'

export const chatHistory = () => async (dispatch, getState) => {
    dispatch({ type: ALL_CHATS_LIST_REQUEST })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.get('/api/my-chats', {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        dispatch({ type: ALL_CHATS_LIST_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: ALL_CHATS_LIST_FAIL, payload: message })
    }
}
