import React, { useEffect, useState, useRef } from 'react'
import io from "socket.io-client";
import { useSelector } from 'react-redux';
import moment from 'moment'

const ChatMessage = ({ history, receiverId }) => {
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [socket, setSocket] = useState(null);
    const chatEndRef = useRef(null)

    const userSignin = useSelector((state) => state.userSignin)
    const { userInfo } = userSignin

    useEffect(() => {
        const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;
        const newSocket = io(SOCKET_SERVER_URL, {
            secure: true,
            transports: ["websocket", "polling"],
        });

        newSocket.on("connect", () => {
            console.log("Connected to socket server", newSocket.id);
            newSocket.emit("joinRoom", userInfo._id); // Ensure user joins a room
        });

        // Listen for incoming messages
        newSocket.on("receiveMessage", (data) => {
            console.log("Received message:", data);
            setMessages((prev) => [...prev, data]);
        });

        setSocket(newSocket);

        return () => newSocket.disconnect();
    }, [userInfo]);

    const sendMessage = () => {
        if (socket && message.trim() !== '') {
            const newMessage = {
                id: String(messages.length + 1), // Generate a unique ID
                sender: userInfo._id,
                receiver: receiverId,
                message,
                timestamp: new Date().toISOString(), // Add a timestamp
            };
            console.log('Sending message:', newMessage);
            socket.emit('sendMessage', newMessage);
            setMessages((prev) => [...prev, newMessage]); // Add message to UI instantly
            setMessage(""); // Clear input
        } else {
            console.log('Socket not connected or message empty.');
        }
        // if (socket && message.trim() !== "") {
        //     const data = { sender: userInfo._id, receiver: receiverId, message };
        //     console.log("Sending message:", data);
        //     socket.emit("sendMessage", data);
        //     setMessages((prev) => [...prev, data]); // Add message to UI instantly
        //     setMessage(""); // Clear input
        // } else {
        //     console.log("Socket not connected or message empty.");
        // }
    };

    useEffect(() => {
        setMessages(history || [])
    }, [history]);

    // Auto-scroll to bottom when messages update
    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);


    return (
        <div className="w-100 user-chat mt-4 mt-sm-0">
            <div className="p-3 px-lg-4 user-chat-border">
                <div className="row">
                    <div className="col-md-4 col-6">
                        <h5 className="font-size-15 mb-1 text-truncate">Messages</h5>
                    </div>
                </div>
            </div>
            <div className="px-lg-2" style={{ height: "520px", overflowY: "auto" }}>
                <div className="chat-conversation p-3">
                    <ul className="list-unstyled mb-0 pe-3" data-simplebar="init" style={{ maxHeight: "450px", overflowY: "auto" }}>
                        <div className="simplebar-wrapper" style={{ margin: "0px -16px 0px 0px" }}>
                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer">
                                </div>
                            </div>
                            <div className="simplebar-mask">
                                <div className="simplebar-offset" style={{ right: "-17px, bottom: 0px" }}>
                                    <div className="simplebar-content-wrapper" style={{ height: "auto", overflow: "hidden scroll" }}>
                                        <div className="simplebar-content" style={{ padding: "0px 16px 0px 0px" }}>
                                            {messages.map((itm, index) => (
                                                <li key={index} className={itm?.sender === userInfo._id ? "right" : ""}>
                                                    <div className="conversation-list">
                                                        {itm?.sender !== userInfo._id ?
                                                            <div className="chat-avatar">
                                                                <img src="assets/images/farmsured_icon.png" alt="avatar-2" />
                                                            </div>
                                                            : null
                                                        }
                                                        <div className="ctext-wrap">
                                                            <div className="conversation-name">
                                                                {itm.sender === userInfo._id ? "Me" : "Other"}
                                                            </div>
                                                            <div className="ctext-wrap-content">
                                                                <p className="mb-0">{itm.message}</p>
                                                            </div>
                                                            <p className="chat-time mb-0">
                                                                <i className="bx bx-time-five align-middle me-1"></i>{" "}
                                                                {moment(itm.timestamp).format("Do MMM YYYY, h:mm a")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="simplebar-placeholder" style={{ width: "auto", height: "886px" }}>
                            </div>
                        </div>
                        <div className="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }}>
                            <div className="simplebar-scrollbar" style={{ transform: "translate3d(0px, 0px, 0px)", display: "none" }}>
                            </div>
                        </div>
                        <div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }}>
                            <div className="simplebar-scrollbar" style={{ height: "228px", transform: "translate3d(0px, 0px, 0px)", display: "block" }}>
                            </div>
                        </div>

                        {/* Dummy div to scroll to bottom */}
                        <div ref={chatEndRef} />
                    </ul>
                </div>

            </div>
            <div className="px-lg-3">
                <div className="p-3 chat-input-section">
                    <div className="row">
                        <div className="col">
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-control chat-input"
                                    placeholder="Enter Message..."
                                    onChange={(e) => setMessage(e.target.value)}
                                    value={message}
                                />

                            </div>
                        </div>
                        <div className="col-auto" onClick={sendMessage}>
                            <button type="submit" className="btn btn-primary chat-send w-md waves-effect waves-light">
                                <span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatMessage