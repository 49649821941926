import axios from 'axios'
import {
    UPDATE_PRODUCE_LIST_REQUEST,
    UPDATE_PRODUCE_LIST_SUCCESS,
    UPDATE_PRODUCE_LIST_FAIL,
} from '../../../constants/adminConstants/farmcluster/clusterContants'

export const updateProduceListingStatus = (
    itemId, offtaker, seller, selectedSP, status
) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_PRODUCE_LIST_REQUEST,
        payload: {
            itemId, offtaker, seller, selectedSP, status
        },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/farmcluster/update-produce-listing',
            {
                itemId, offtaker, seller, selectedSP, status
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: UPDATE_PRODUCE_LIST_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: UPDATE_PRODUCE_LIST_FAIL, payload: message })
    }
}
